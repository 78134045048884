import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVehicles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('vehicle', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchArchiveVehicles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchArchiveVehicles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },



    fetchVehicle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`vehicle/${id}`)
          .then((response) => {
            if (response.data.status == 'P') {
              response.data.status = 'passive';
            } else if (response.data.status == 'D') {
              response.data.status = 'deleted';
            } else {
              response.data.status = 'active';
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    permissionsAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('permissions')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addVehicle(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vehicle', userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    vehicleStatus(ctx, tempData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`vehicleStatus/${tempData.userId}`, tempData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },



    updateVehicle(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`vehicle/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    deleteVehicle(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteVehicle/${data.id}`, data)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissionsData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
