<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Vehicle</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="vehicleRules">
        <b-form>
          <b-row class="mt-2 border-bottom pb-1">
            <!-- Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="Type">Type</label>
                <validation-provider name="Type" #default="{ errors }" rules="required">
                  <b-form-input id="type" type="text" v-model="vehicleData.type"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Seat Count" label-for="seat_count">
                <validation-provider name="Seat Count" #default="{ errors }" rules="required">
                  <!-- <b-form-input id="seat_count" type="number" v-model="vehicleData.seat_count"></b-form-input> -->
                  <cleave id="seat_count" v-model="vehicleData.seat_count" :state="errors.length > 0 ? false : null" class="form-control" :raw="true" :options="$func.getInputOptions().number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Vehicle Registration" label-for="reg">
                <validation-provider name="Vehicle Registration" #default="{ errors }" rules="required">
                  <b-form-input id="vehicle_reg" type="text" v-model="vehicleData.vehicle_reg"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Ownership" label-for="ownership">
                <validation-provider name="Ownership" #default="{ errors }" rules="required">
                  <b-form-input id="ownership" type="text" v-model="vehicleData.ownership"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="mot_due">MOT Due</label>
                <flat-pickr id="mot_due" class="form-control" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" placeholder="DD/MM/YYYY" v-model="vehicleData.mot_due" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="service_due">Service Due</label>
                <flat-pickr id="service_due" class="form-control" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" placeholder="DD/MM/YYYY" v-model="vehicleData.service_due" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="tax_due">Tax Due</label>
                <flat-pickr id="tax_due" class="form-control" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" placeholder="DD/MM/YYYY" v-model="vehicleData.tax_due" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12" sm="12" md="12" xl="3">
              <b-form-group label="Fault Description" label-for="fault">
                <b-form-input id="fault" type="text" v-model="faultData.description"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="12" md="12" xl="3">
              <b-form-group>
                <label for="tax_due">Fault Date</label>
                <flat-pickr id="tax_due" class="form-control" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" placeholder="DD/MM/YYYY" v-model="faultData.date" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="12" md="12" xl="6">
              <b-button @click.prevent="addFault" type="add" variant="primary" class="mr-1 mt-2 float-left">
                Add
              </b-button>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col cols="12" lg="12" md="12" sm="12">
              <b-alert variant="secondary" show>
                <div class="alert-body">
                  <span class="text-center h5">Fault List</span>
                </div>
              </b-alert>

              <b-table striped hover ref="refUserListTable" class="position-relative" :items="fault_list" responsive :small="true" head-variant="dark" :fields="tableColumns" show-empty empty-text="No matching records found">
                <!-- Column: Id -->
                <template #cell(id)="data">
                  <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
                  <strong class="text-primary">#{{ data.value }}</strong>
                </template>

                <!-- Column: Name -->
                <template #cell(description)="data">
                  {{ data.value }}
                </template>

                <!-- Column: Name -->
                <template #cell(date)="data">
                  {{ data.value }}
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                  <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                    Active
                  </b-badge>

                  <b-badge v-if="data.value == 'P'" pill :variant="`light-danger`" class="text-capitalize">
                    Passive
                  </b-badge>

                  <b-badge v-if="data.value == 'D'" pill :variant="`danger`" class="text-capitalize">
                    Deleted
                  </b-badge>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <b-button v-if="vehicleId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="vehicleData.status != 'deleted'" variant="primary" class="mr-1 float-right"> Save Changes </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import vehicleStoreModule from '../vehicleStoreModule';

import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    vSelect,
    Cleave,
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const VEHICLE_APP_STORE_MODULE_NAME = 'vehicle';

    // Register module
    if (!store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_APP_STORE_MODULE_NAME, vehicleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLE_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var vehicleData = {
      type: null,
      seat_count: null,
      vehicle_reg: null,
      ownership: null,
      mot_due: null,
      service_due: null,
      tax_due: null,
      fault_list: [],
    };

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Passive', value: 'passive' },
      { label: 'Delete', value: 'deleted' },
    ];

    return {
      fault_list: [],
      userData: JSON.parse(localStorage.getItem('userData')),

      vehicleData: vehicleData,

      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,

      faultData: { description: null, date: null, user: null },

      roleOptions: [],
      statusOptions,
      vehicleId: null,
      formShow: false,
      permissions: [],

      tableColumns: [
        { key: 'description', sortable: true, class: 'text-center' },
        { key: 'date', sortable: true, class: 'text-center' },
        { key: 'user', sortable: false, class: 'text-center' },
      ],

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  created() {
    this.getVehicleById();
  },

  methods: {
    addFault() {
      if (this.faultData.description != null && this.faultData.description != undefined && this.faultData.description != '' && this.faultData.date != null && this.faultData.date != undefined && this.faultData.date != '') {
        this.faultData.user = (this.userData.name ? this.userData.name : '') + ' ' + (this.userData.surname ? this.userData.surname : '');

        this.fault_list.push(this.faultData);
        this.faultData = { description: null, date: null, user: null };
      }
    },

    getVehicleById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.vehicleId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('vehicle/fetchVehicle', { id: router.currentRoute.params.id })
          .then((response) => {
            if (response.status == 200) {
              this.vehicleData = response.data;
              this.fault_list = response.data.fault_list ?? [];
              this.formShow = false;
            } else {
              router.push({ name: 'error-404' });
            }
          })
          .catch((error) => {
            router.push({ name: 'error-404' });
          });
      } else {
        this.formShow = false;
      }
    },

    formSubmitted() {
      this.formShow = true;
      this.vehicleData.fault_list = this.fault_list;
      this.$refs.vehicleRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store.dispatch('vehicle/updateVehicle', this.vehicleData).then((response) => {
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Vehicle Updated Successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.formShow = false;

                router.push({ name: 'vehicle-view', params: { id: response.data.id } });
              }
            });
          } else {
            store.dispatch('vehicle/addVehicle', this.vehicleData).then((response) => {
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Vehicle Added Successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                setTimeout(function() {
                  this.formShow = false;
                  router.push({ name: 'vehicle-view', params: { id: response.data.id } });
                }, 500);
              }
            });
          }
        } else {
          this.formShow = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
